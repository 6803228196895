<template>
  <div class="box">
    <div class="item since" v-for="(item, index) in myList" :key="index" @click="changeCheckMy(item)">
      <img :src="require('@/assets/home/wj_tb_wj.png')" alt="" />
      <div class="info">
        <div class="tit">{{ item.Assessment_Name }}</div>
        <div class="bottom">
          <div class="child1">
            <div>发布人：{{ item.Publish_User_Name }}</div>
            <div>题目：{{ item.Parent_Count }}</div>
            <!-- <div class="not" v-if="!item.IsSelfComplate">未自评</div> -->
          </div>
          <div class="child2" style="margin-top: 5px; font-style: 12px">
            结束日期：<span>{{
              $moment(item.Answer_End_Time).format("YYYY-MM-DD")
              }}</span>
          </div>
        </div>
        <img v-if="!item.IsSelfComplate" :src="require('@/assets/home/wj_bq_wzp.png')" alt="" class="not" />
      </div>
    </div>
    <div class="item check" v-for="(item, index) in list" :key="index" @click="changeCheck(item)">
      <img :src="require('@/assets/home/wj_tb_sp.png')" alt="" />
      <img class="mySp" :src="require('@/assets/home/wj_bq_wdkh.png')" alt="" />
      <div class="info">
        <div class="tit">{{ item.Assessment_Name }}</div>
        <div class="bottom">
          <div class="child2">
            评分进度：({{ item.ComplateCount }}/{{ item.Allcount }})
            <span>
              {{ ((item.ComplateCount / item.Allcount) * 100).toFixed(0) }}%
            </span>
          </div>
          <div class="child2" style="margin-top: 5px; font-style: 12px">
            结束日期：<span>{{
              $moment(item.Answer_End_Time).format("YYYY-MM-DD")
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        myList: [],
        list: [],
      };
    },
    props: {
      type: String,
      active: Number,
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/PFM/PfmRecord/GetTeacherPfmList",
          completed(m) {
            if (self.type == "1") {
              self.list = m.pFM_Teacher_Mobile_Man_DTOs;
              self.myList = [];
            } else {
              if (self.active == 1) {
                self.myList = m.pFM_Teacher_Mobile_Self_DTOs;
                self.list = [];
              } else {
                self.myList = m.pFM_Teacher_Mobile_Self_History_DTOs;
                self.list = [];
              }
            }
          },
        });
      },
      changeCheckMy(item) {
        if (item.IsSelfComplate == 1) {
          this.$router.push({
            path: "/check/info",
            query: { guid: item.Assessment_Guid },
          });
        } else {
          this.$router.push({
            path: "/check/edit",
            query: { guid: item.Assessment_Guid },
          });
        }
      },
      changeCheck(item) {
        this.$router.push({
          path: "/check/list",
          query: { guid: item.Assessment_Guid },
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .page {
    background: #f4f8fb;
  }

  .banner {
    img {
      width: 100%;
    }
  }

  .title {
    color: #98a3ad;
    text-align: center;
    margin: 10px 0;
  }

  .box {
    .item {
      width: 90%;
      margin: 0 auto 10px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 20px;

      img {
        width: 60px;
        height: 60px;
      }

      .info {
        margin-left: 10px;

        .tit {
          color: #222222;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .bottom {
          color: #98a3ad;
          font-size: 14px;

          .child1 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >div {
              margin: 0 4px;
            }

            .not {
              color: #ff2c2c;
              border: 1px solid #ffa0a0;
              border-radius: 50px;
              padding: 2px 10px;
            }
          }

          .child2 {
            span {
              color: #0195ff;
            }
          }
        }
      }
    }

    .since {
      position: relative;

      .not {
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 20px;
      }
    }

    .check {
      position: relative;

      .mySp {
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 23px;
      }
    }
  }
</style>